// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// -------------------------------------------- //
// VUETIFY PLUGIN
// -------------------------------------------- //

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#dd1122',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#dd1122',
        secondary: '#424242',
      }
    },
  },
  icons: {
    iconfont: 'fa',
  },
});
