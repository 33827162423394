// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import Vue from 'vue';
import App from './App.vue';
import '@/plugins/firebase';
import './registerServiceWorker';
import router from './router';
import store from './store/_global';
import vuetify from './plugins/vuetify';

// -------------------------------------------- //
// VUE APP
// -------------------------------------------- //

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

// -------------------------------------------- //
// FILTERS
// -------------------------------------------- //

Vue.filter('capitalize', function (value: string) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
