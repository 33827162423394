




























  import { defineComponent } from 'vue';
  import type { State as AuthState } from "@/store/mod/auth";

  export default defineComponent({
    data() {
      return {};
    },
    computed: {
      user(): AuthState['user'] | undefined {
        return this.$store.getters['auth/user']
      },
      claims(): AuthState['claims'] {
        return this.$store.getters["auth/claims"];
      },
      isDarkMode: {
        get() {
          return this.$store.getters["user/darkMode"] as boolean;
        },
        set(value: boolean) {
          this.$store.dispatch("user/setDarkMode", value);
        }
      },
      isRTL: {
        get() {
          return this.$store.getters["user/rtl"] as boolean;
        },
        set(value: boolean) {
          this.$store.dispatch("user/setRTL", value);
        }
      },
    },
  });
