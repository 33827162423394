// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import { GetterTree, MutationTree, ActionTree } from "vuex";
import { firestoreAction } from 'vuexfire';
import { firestore as db } from '@/plugins/firebase';
import store, { RootState } from "@/store/_global";
import type { State as AuthState } from "@/store/mod/auth";
import { AdminReport, Election, VotingLog } from "@/types/main";
import type firebase from "firebase/compat/app";
type DocumentData = firebase.firestore.DocumentData;

// -------------------------------------------- //
// TYPES
// -------------------------------------------- //

export type State = ReturnType<typeof state>

// -------------------------------------------- //
// STORE
// -------------------------------------------- //

const state = () => ({
  elections: undefined as Array<Election> | undefined,
  election: undefined as Election | undefined,
  votingLog: undefined as VotingLog | undefined,
  reports: undefined as Array<AdminReport> | undefined,
});

const actions = <ActionTree<State, RootState>>{
  bindElectionsCollection: firestoreAction(
    ({bindFirestoreRef}) => {

      let query: DocumentData = db.collection('elections');
      const claims: AuthState['claims'] = store.getters['auth/claims'];
      if (!claims?.roles['admin_elections']) query = query.where('visible', '==', true);

      return bindFirestoreRef(
        'elections',
        query.orderBy("startDate")
      );
    }
  ),

  bindElection: firestoreAction(
    ({bindFirestoreRef, rootGetters, dispatch}, electionId?: string) => {
      if (!electionId) return;
      return bindFirestoreRef(
        'election',
        db.collection('elections').doc(electionId)
      );
    }
  ),

  bindVotingLog: firestoreAction(
    ({bindFirestoreRef, rootGetters, dispatch}, electionId?: string) => {

      const user: AuthState['user'] = rootGetters['auth/user'];
      if (!electionId) return;
      if (!user) {
        dispatch('unbindVotingLog');
        return
      }

      return bindFirestoreRef(
        'votingLog',
        db.collection('elections').doc(electionId).collection('votingLog').doc(user.uid)
      );
    }
  ),

  bindReports: firestoreAction(
    ({bindFirestoreRef, rootGetters, dispatch}, electionId: string) => {

      const user: AuthState['user'] = rootGetters['auth/user'];
      const claims: AuthState['claims'] = rootGetters['auth/claims'];

      if (!electionId) return;
      if (!user || !claims?.roles['admin_elections']) {
        dispatch('unbindReports');
        return
      }

      return bindFirestoreRef(
        'reports',
        db.collection('elections').doc(electionId).collection('admin')
      );
    }
  ),

  unbindElectionsCollection: firestoreAction(({unbindFirestoreRef: unbind}) => unbind('elections')),
  unbindElection: firestoreAction(({unbindFirestoreRef: unbind}) => unbind('election')),
  unbindVotingLog: firestoreAction(({unbindFirestoreRef: unbind}) => unbind('votingLog')),
  unbindReports: firestoreAction(({unbindFirestoreRef: unbind}) => unbind('reports')),
};

const mutations = <MutationTree<State>>{};

const getters = <GetterTree<State, RootState>>{
  elections: (state): State['elections'] => state.elections || [],
  election: (state): State['election'] => state.election,
  votingLog: (state): State['votingLog'] => state.votingLog,
  reports: (state): State['reports'] => state.reports,
};

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
