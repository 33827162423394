import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-6"},[_c(VContainer,[_c(VRow,[_c(VCol,[_c('h1',[_vm._v("Settings")])]),(_vm.claims.roles.admin)?_c(VCol,{staticClass:"text-end",attrs:{"cols":"4"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"outlined":"","to":{ name: 'admin_settings' }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("fas fa-shield-alt")]),_vm._v("Admin")],1)],1):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VCard,[_c(VCardTitle,{attrs:{"overline":""}},[_vm._v("Toggle Dark Mode")]),_c(VCardText,[_vm._v("A dark theme for night, or preference.")]),_c(VCardActions,[_c(VSwitch,{staticClass:"px-2",attrs:{"label":"Enables dark theme"},model:{value:(_vm.isDarkMode),callback:function ($$v) {_vm.isDarkMode=$$v},expression:"isDarkMode"}},[_vm._v("Dark Mode")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VCard,[_c(VCardTitle,{attrs:{"overline":""}},[_vm._v("Right-to-Left")]),_c(VCardText,[_vm._v("Change app layout from LtR to RtL")]),_c(VCardActions,[_c(VSwitch,{staticClass:"px-2",attrs:{"label":"Enable RtL"},model:{value:(_vm.isRTL),callback:function ($$v) {_vm.isRTL=$$v},expression:"isRTL"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }