











  import { defineComponent } from 'vue';
  import type { State as AuthState } from "@/store/mod/auth"

  export interface NavBarItem{
    icon: string,
    title: string,
    route: Object,
    priority: number
  }

  export default defineComponent({
    name: "NavMenu",
    data: () => ({
      publicItems: [
        {
          icon: 'fas fa-vote-yea fa-fw',
          title: 'Elections',
          route: {name: 'elections'},
          priority: 10
        },
      ],
      authItems: [
        {
          icon: 'fas fa-cog fa-fw',
          title: 'Settings',
          route: {name: 'settings'},
          priority: 999
        },
      ],
      guestItems: [],
    }),
    computed: {
      user(): AuthState['user'] {
        return this.$store.getters['auth/user']
      },
      drawerItems() {
        let items: Array<NavBarItem> = this.publicItems;

        if (this.user) items = [...items, ...this.authItems];
        else items = [...items, ...this.guestItems];

        items.sort((a,b) => a.priority > b.priority ? 1 : ((a.priority < b.priority) ? -1 : 0));

        return items;
      }
    }
  })
