import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{staticClass:"d-print-none",attrs:{"app":"","prominent":"","elevate-on-scroll":"","shrink-on-scroll":"","fade-img-on-scroll":"","color":"#dd1122","src":require("./assets/campus.jpg"),"dark":""},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c(VImg,_vm._b({attrs:{"gradient":"to top right, rgba(221, 17, 34, 1), rgba(0,0,0,.3)"}},'v-img',props,false))]}}])},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c(VIcon,[_vm._v("as fa-bars")])],1),_c(VToolbarTitle,[_vm._v("MIT Student Life")])],1),_c(VNavigationDrawer,{staticClass:"d-print-none",attrs:{"app":"","right":_vm.$vuetify.rtl},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VList,[_c(VScrollXTransition,{attrs:{"leave-absolute":""}},[_c(VListItem,{key:!!_vm.user,attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v(_vm._s(_vm.user && _vm.user.displayName || "Guest"))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.user && _vm.user.email || "Not Logged In"))])],1)],1)],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c(VList,{attrs:{"nav":"","dense":""}},[_c(VScrollXTransition,{attrs:{"leave-absolute":""}},[_c(VListItem,{key:!!_vm.user,on:{"click":function($event){_vm.user ? _vm.logout() : _vm.login()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("fas fa-sign-"+_vm._s(_vm.user ? "out" : "in")+"-alt")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Log "+_vm._s(_vm.user ? "Out" : "In"))])],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VDivider),_c('NavMenu')],1),_c(VMain,[_c(VScrollYReverseTransition,{attrs:{"mode":"out-in"}},[_c('div',{staticClass:"mx-1 mx-lg-6"},[_c('router-view'),(!_vm.$route.name)?_c(VContainer,[_c(VRow,{staticClass:"justify-center align-center"},[_c(VCol,{staticClass:"flex-grow-0"},[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1)],1)],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }