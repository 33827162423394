// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import Vue from 'vue';
import {auth} from "@/plugins/firebase";
import Router from 'vue-router';
import Settings from './views/Settings.vue';
import store from '@/store/_global';

// -------------------------------------------- //
// TYPES
// -------------------------------------------- //

import type { State as AuthState } from "@/store/mod/auth"
type Claims = AuthState['claims'];

// -------------------------------------------- //
// ROUTER
// -------------------------------------------- //

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    // General
    {
      path: '/',
      redirect: { name: 'elections' }
    },

    // Elections Module

    {
      path: '/elections',
      name: 'elections',
      component: () => import(/* webpackChunkName: "elections" */ './views/elections/Index.vue')
    },
    {
      path: '/elections/admin',
      name: 'elections-admin',
      component: () => import(/* webpackChunkName: "elections-admin" */ './views/elections/Admin.vue'),
      meta: {requiredClaim: 'admin_elections'},
    },
    {
      path: '/elections/admin/:id/:report?',
      name: 'elections-reporting',
      component: () => import(/* webpackChunkName: "elections-admin" */ './views/elections/Reporting.vue'),
      meta: {requiredClaim: 'admin_elections'},
      children: [

      ]
    },
    {
      path: '/elections/:id',
      name: 'elections-id',
      component: () => import(/* webpackChunkName: "elections" */ './views/elections/Voting.vue')
    },

    // Settings Module

    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      // navigation guard: route meta tag for easier management of access control.
      meta: {requiresAuth: true}
    },
    {
      path: '/settings/admin',
      name: 'admin_settings',
      component: () => import(/* webpackChunkName: "AdminSettings" */ './views/AdminSettings.vue'),
      // navigation guard for admin-only pages
      meta: {requiredClaim: 'admin'}

    }
  ]
});

export const defaultPath = {name: 'elections'};

// only allow authed users to see auth required pages.
router.beforeEach((to, from, next) => {
  const user: AuthState['user'] = store.getters['auth/user'];
  const claims: AuthState['claims'] = store.getters['auth/claims'];

  const requiresAuth = to.matched.some(
    record => record.meta.requiresAuth
  );

  const requiresGuest = to.matched.some(
    record => record.meta.requiresGuest
  );

  const requiredClaims = to.matched.reduce((acc, curr) => {
    const claim: string = curr.meta.requiredClaim;
    if (claim && acc.indexOf(claim) === -1) acc.push(claim);
    return acc;
  }, [] as string[]);

  function continueGuard(authed: boolean, claims: Claims | undefined) {
    if (to.matched.some(record => record.meta.system)) {
      next();
      return;
    }
    // TODO: Toasts?

    // if a user isn't logged in and tries to access a page with auth required,
    // redirect to the home page.
    // TODO: Ask user to login. Perhaps trigger sign-in popup?
    if (requiresAuth && !authed) next(defaultPath);

    // if an authed user trys to access a page that that requires being a guest,
    // redirect to the home page
    // TODO: Toast user - "You are already logged in"
    else if (requiresGuest && authed) next(defaultPath);

    // If the current route requires specific claims.
    else if (requiredClaims.length > 0) {
      for (const claim of requiredClaims) {
        if (claims?.roles?.[claim]) continue;
        next(defaultPath);
        return;
      }
      next();
    }
    // else, go to the page
    else next();
  }

  // If this is a special page, or the user hasn't been handled yet
  if (user === undefined && requiresAuth || requiredClaims.length > 0) {
    let unsub = auth.onAuthStateChanged(function (user) {
      unsub();
      if (!user) continueGuard(false, claims);
      else user.getIdTokenResult().then((idTokenResult) => {
        continueGuard(true, idTokenResult.claims as Claims);
      });
    });
  } else continueGuard(true, claims);

});

export default router;
