















































import { defineComponent } from "vue";
import { auth, login } from "@/plugins/firebase";
import NavMenu from "@/components/NavMenu.vue";
import type { State as AuthState } from "@/store/mod/auth";
import type { StateUserDoc } from "@/store/mod/user";

export default defineComponent({
  name: "App",
  components: { NavMenu },
  data: () => ({
    // nav-drawer toggle
    drawer: undefined as undefined | boolean,
  }),
  // login handling
  methods: {
    login() {
      login().catch((error: any) => {
        console.error("Sign in failed: " + error);
      });
    },
    logout() {
      auth.signOut();
    },
  },
  // Computed data field & Watcher to apply the Dark Mode from VueX
  computed: {
    user(): AuthState["user"] {
      return this.$store.getters["auth/user"];
    },
    darkMode(): StateUserDoc["darkMode"] {
      return this.$store.getters["user/darkMode"];
    },
    rtl(): StateUserDoc["rtl"] {
      return this.$store.getters["user/rtl"];
    },
  },
  watch: {
    darkMode: {
      // handler is fired as soon as initialized due to the immediate flag,
      // as opposed to on first value change.
      handler: function (val) {
        this.$vuetify.theme.dark = val;
        // web storage API - persists these values through reloads
        localStorage.darkMode = !!val;
      },
      immediate: true,
    },
    rtl: {
      // handler is fired as soon as initialized due to the immediate flag,
      // as opposed to on first value change.
      handler: function (val) {
        this.$vuetify.rtl = val;
        // web storage API - persists these values through reloads
        localStorage.rtl = !!val;
      },
      immediate: true,
    },
  },
});
