// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import { GetterTree, MutationTree, ActionTree } from "vuex";
import { auth } from '@/plugins/firebase';
import { RootState } from "@/store/_global";
import { FirebaseClaims, FirebaseUser } from "~/firebase";

// -------------------------------------------- //
// TYPES
// -------------------------------------------- //

interface User {
  displayName?: string,
  email?: string,
  photoURL?: string,
  uid: string
}

type Claims = FirebaseClaims & {
  roles: { [p: string]: boolean }
}

export type State = ReturnType<typeof state>

// -------------------------------------------- //
// STORE
// -------------------------------------------- //

const state = () => ({
  // expects a user object from auth from setUser() mutation
  user: undefined as User | undefined,
  claims: undefined as Claims | undefined,
});

const actions = <ActionTree<State, RootState>>{
  // binds a user document to the application.
  setUser: async ({commit, dispatch}, user: FirebaseUser) => {
    commit("setUserMut",
      user ? {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.providerData?.[0]?.photoURL,
        uid: user.uid
      } as User : null
    );

    await dispatch("setUserClaims");
  },

  setUserClaims: async ({commit}, refresh) => {
    let fbUser = auth.currentUser;
    let claims: Claims = {roles: {}};
    if (fbUser) Object.assign(claims, (await fbUser.getIdTokenResult(refresh)).claims);
    commit("setClaimsMut", claims);
    return claims;
  }
};

const mutations: MutationTree<State> = {
  setUserMut: (state, user) => {
    state.user = user;
  },
  setClaimsMut: (state, claims) => {
    state.claims = claims;
  },
};

const getters = <GetterTree<State, RootState>>{
  user: (state): State['user'] | undefined => state.user,
  claims: (state): State['claims'] => (state.claims ?? {roles: {guest: true}}),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
