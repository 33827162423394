// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import { GetterTree, MutationTree, ActionTree } from "vuex";
import { firestoreAction } from 'vuexfire';
import { firestore as db, auth as auth } from '@/plugins/firebase';
import { RootState } from "@/store/_global";

// -------------------------------------------- //
// TYPES
// -------------------------------------------- //

interface UserDoc {
  darkMode: boolean
  rtl: boolean
}

export type State = ReturnType<typeof state>
export type StateUserDoc = NonNullable<State['userDoc']>;

// -------------------------------------------- //
// STORE
// -------------------------------------------- //

const state = () => ({
  userDoc: undefined as UserDoc | undefined
});

const actions = <ActionTree<State, RootState>>{
  bindUserDocument: firestoreAction(
    ({bindFirestoreRef}) => bindFirestoreRef(
      'userDoc',
      db.collection('users').doc(auth.currentUser?.uid)
    )
  ),

  unbindUserDocument: firestoreAction(({unbindFirestoreRef: unbind}) => unbind('userDoc')),

  setDarkMode: firestoreAction(
    ({state, getters}, darkMode: StateUserDoc['darkMode']) => {
      if (!auth.currentUser) return;

      db.collection('users')
        .doc(auth.currentUser.uid)
        .set({darkMode: darkMode} as Partial<StateUserDoc>, {merge: true})
        .catch((err) => console.log('Unable to set Dark Mode', err));
    }
  ),

  setRTL: firestoreAction(
    ({state, getters}, rtl: StateUserDoc['rtl']) => {
      if (!auth.currentUser) return;

      db.collection('users')
        .doc(auth.currentUser.uid)
        .set({rtl: rtl} as Partial<StateUserDoc>, {merge: true})
        .catch((err) => console.log('Unable to set RTL', err));
    }
  ),
};

const mutations = <MutationTree<State>>{};

const getters = <GetterTree<State, RootState>>{
  userDocument: (state): State['userDoc'] => state.userDoc,

  darkMode: (state): StateUserDoc['darkMode'] => {
    return state.userDoc ?
      state.userDoc.darkMode :
      (localStorage.getItem('darkMode') === 'true');
  },

  rtl: (state): StateUserDoc['rtl'] => {
    return state.userDoc ?
      state.userDoc.rtl :
      // had to do some weird casting here
      (localStorage.getItem('rtl') === 'true');
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
