import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VSlideYTransition,{attrs:{"group":""}},_vm._l((_vm.drawerItems),function(item){return _c(VListItem,{key:item.title,attrs:{"link":"","to":item.route}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }