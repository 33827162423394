// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import Vue, {  InjectionKey } from 'vue';
import VueX, { Store } from 'vuex';
import { vuexfireMutations } from 'vuexfire';

// modules
import auth from './mod/auth';
import user from './mod/user';
import elections from "./mod/elections";
import Vuex from "vuex";

// -------------------------------------------- //
// TYPES
// -------------------------------------------- //

export interface RootState {}

// -------------------------------------------- //
// PLUGIN
// -------------------------------------------- //

Vue.use(Vuex);

// -------------------------------------------- //
// STORE
// -------------------------------------------- //

// define injection key
export const key: InjectionKey<Store<RootState>> = Symbol()

export default new VueX.Store<RootState>({
  /*
    disallows any modification to the state without mutation during testing
    https://vuex.vuejs.org/guide/strict.html
  */
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {
    ...vuexfireMutations,
  },
  actions: {},
  modules: {
    auth,
    user,
    elections,
  }
});
