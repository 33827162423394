// -------------------------------------------- //
// IMPORTS
// -------------------------------------------- //

import Vue from 'vue';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import store from '../store/_global';
import config from '@/../firebase.conf.json';
import router, {defaultPath} from "@/router";

// -------------------------------------------- //
// FIREBASE SETUP
// -------------------------------------------- //

// TODO - Verify TS types

// checks if a firebase is already initialised:
// if so will get a ref to the existing service.
// otherwise it will init a new service.
const fb = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

// noinspection JSUnusedGlobalSymbols
Vue.prototype.$firebase = fb;

export const auth = fb.auth();
export const firestore = fb.firestore();
export const functions = fb.functions();
export const storage = fb.storage();

// debug emulator for cloud function testing
//functions.useFunctionsEmulator('http://localhost:5001');

export const login = function() {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  // i18n function - assigns browser lang to popup
  auth.useDeviceLanguage();

  provider.setCustomParameters({
    // Force re-login.
    tenant: process.env.VUE_APP_MS_TENANT,
    prompt: 'login',
    // Target specific email with login hint.
    domain_hint: 'user123@manukaumail.com'
  });

  return auth.signInWithPopup(provider)
};

// auth event listener (Firebase)
auth.onAuthStateChanged(function (user) {
  // uses vuex to store the user in the application state
  // as well as binding a reference to the user document in firebase
  if (user) {
    store.dispatch("auth/setUser", user);
    store.dispatch("user/bindUserDocument");
    // Check if we can be here

    // TODO: Rework this to manage other auth guards.
    const requiresGuest = router.currentRoute.matched.some(
      record => record.meta["requiresGuest"]
    );
    if (requiresGuest) router.push(defaultPath);
  }
  // on logout sets the user in the application state to null,
  // redirects to the home page,
  // and unbinds the user document from Firebase
  else {
    store.dispatch("auth/setUser", null);
    store.dispatch("user/unbindUserDocument");

    // check if we can be here
    const requiresAuth = router.currentRoute.matched.some(
      record => record.meta.requiresAuth
    );
    // TODO: "This page requires you to login"
    if (requiresAuth) router.push(defaultPath);
  }

  const requiredClaims = router.currentRoute.matched.reduce((acc, curr) => {
    const claim: string = curr.meta.requiredClaim;
    if (claim && acc.indexOf(claim) === -1) acc.push(claim);
    return acc;
  }, [] as string[]);

  if (user) {
    user.getIdTokenResult().then((idTokenResult) => {
      if (requiredClaims.length > 0) {
        for (const claim of requiredClaims) {
          if (idTokenResult.claims.roles[claim]) continue;
          router.push(defaultPath);
          return;
        }
      }
    });
  } else if (requiredClaims.length > 0) router.push(defaultPath);
});
